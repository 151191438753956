<template>
    <div>
       <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
            <div>
            <b-card-title>{{$t('curlang')=='en'?'Delete Quest':'Eliminar Desafio'}}</b-card-title>
            </div>
            <div>
            <b-form-group>
        <b-input-group>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            :placeholder="$t('curlang')=='en'?'Filter by Search':'Filtrar por Busqueda'"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              {{this.$t('curlang')=='en'?'Clear':'Limpiar'}}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
        </div>
        </b-card-header> 
        <b-table
         :busy="isBusy"
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        selectable
        select-mode="range"
         @row-clicked="openQuest"
        >
         <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(options.description)="data">
        <div v-html="data.value"></div>
      </template>
           <template #cell(options.status)="data">
        <b-badge
        :variant="data.value?'light-success':'light-danger'"
        >
       {{data.value?'Active':'Desactive'}}
        </b-badge>
      </template>
        </b-table>
         <b-col cols="12">
       <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
    </b-col>
       </b-card>
       <b-modal
       v-if="quest"
          v-model="openmodal"
      title="Update or Delete Quest"
      ok-only
      ok-title="Save"
      size="lg"
      @ok="DeleteQuest"
    >
    <b-row class="match-height" v-if="quest">
      <b-col>
         <b-form-group label="Quest Title English">
      <b-form-input
      v-model="quest.options.title"
      type="text"
      rows="5"
      />
      </b-form-group>
      </b-col>
      <b-col>
          <b-form-group label="Quest Tittle Spanish">
      <b-form-input
       v-model="quest.options.titlees"
      type="text"
      rows="5"
      />
      </b-form-group>
      </b-col>
    </b-row>
    <b-row class="match-height" v-if="quest">
      <b-col>
        <b-form-group label="Start Date">
             <flat-pickr
             v-model="quest.options.startDate"
       class="form-control"
      :placeholder="quest.options.startDate"
      required
      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
    />
        </b-form-group>
      </b-col>
       <b-col>
        <b-form-group label="Due Date">
             <flat-pickr
             v-model="quest.options.dueDate"
       class="form-control"
      :placeholder="quest.options.dueDate"
      required
      :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
    />
        </b-form-group>
      </b-col>
    </b-row>
      <b-form-checkbox
      v-if="quest"
      switch
      inline
       class="custom-control-success"
        v-model="quest.options.status"
    >
      Activate/Desactive Quest
    </b-form-checkbox>
    </b-modal>
    </div>
</template>
<script>
import {BCard,BCardHeader,BCardTitle,BCardBody,BCardText,BTable,BSpinner,BAvatar,BModal,BImg,BAlert,
BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,BBadge,BFormCheckbox,
BFormDatepicker} from "bootstrap-vue"
import SellerService from '@/services/SellerService'
import flatPickr from 'vue-flatpickr-component'
export default {
    components:{BCard,BCardHeader,BCardTitle,BCardBody,BTable,BSpinner,BAvatar,BModal,BCardText,BImg,BAlert,
    BRow,BCol,BFormInput,BFormGroup,BInputGroup,BInputGroupAppend,BButton,BPagination,BBadge,BFormCheckbox,
    BFormDatepicker,flatPickr},
    data(){
        return{
            isBusy:true,
            openmodal:false,
              fields:[{label:this.$t("curlang")=="en"?"Quest Title":"Titulo del Desafio",key:"options.title"},
            {label:this.$t("curlang")=="en"?"Quest Description":"Descripcion del Desafio",key:"options.description"},
            {label:"Due Date",key:"options.dueDate"},
            {label:this.$t("curlang")=="en"?"Quest Status":"Estado del Desafio",key:"options.status"}
            ],
            items:[],
            quest:null,
          perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
        }
    },
    mounted(){
        this.getQuests()
         this.totalRows = this.items.length
    },
    methods:{
         onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
        getQuests:async function(){
        const qu=(await SellerService.getQuestsWithoutImg(JSON.parse(localStorage.getItem("auth")).discordId)).data
       this.items=qu
       this.items=this.items.filter(item=> item.options.status==true);
       this.items.sort((a, b) => {
          const dateA = new Date(a.options.dueDate);
             const dateB = new Date(b.options.dueDate);
             return dateB - dateA;
      });
        console.log(this.items);
       this.totalRows = this.items.length
       this.isBusy=false
      },
      openQuest(item){
        this.openmodal=true
        this.quest=item
      },
      updateQuest:async function(){
         const ins=(await SellerService.DeleteQuest(JSON.parse(localStorage.getItem("auth")).discordId,this.quest))
          this.$swal({
            icon: 'success',
            title: '',
            text: 'This quest has updated',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
      },
        DeleteQuest:function(){
          if (this.quest.options.status) {
            this.updateQuest()
          }
          else{
            this.$swal({
        title: 'Are you sure to delete this quest?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText:'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
           const ins=(await SellerService.DeleteQuest(JSON.parse(localStorage.getItem("auth")).discordId,this.quest))
            this.getQuests()
          this.$swal({
            icon: 'success',
            title: '',
            text: 'This quest has deleted',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
      }
        },
 
    }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>